import './companySelect.css';
import Colgate from './Colgate';
import Toms from './Toms';
import Hills from './Hills';
import EltaMD from './EltaMD';
import {
    Link,
    Switch,
    Route,    
    useRouteMatch
} from "react-router-dom";

function Company(){
    let { path } = useRouteMatch();
    return(
        <Switch>
            <Route exact path = {path}>
                <CompanySelect />
            </Route>
            <Route path = {`${path}/Hills`}>
                <Hills />
            </Route>
            <Route path = {`${path}/Toms`}>
                <Toms />
            </Route>
            <Route path = {`${path}/Colgate`}>
                <Colgate />
            </Route>
            <Route path = {`${path}/EltaMD`}>
                <EltaMD />
            </Route>
        </Switch>
    )
}

function CompanySelect(){
    let { url } = useRouteMatch();
    return (
        <div className= "companies">
            <div className = "header">
                <h1>Select Company</h1>
            </div>   
            <div className= "row">
                <div className = "column">
                    <img className="pic" src="imgs/colgate-logo.png" alt="Colgate"></img>
                    <Link to={`${url}/Colgate`} style={{ textDecoration: 'none', color: 'black'}} ><h3>Colgate-Palmolive</h3></Link>
                </div>
                <div className = "column">
                    <img className="pic" src="imgs/hills-logo.png" alt="Hill's Pet Nutrition"></img>
                    <Link to={`${url}/Hills`} style={{ textDecoration: 'none', color: 'black'}} ><h3>Hill's Pet Nutrition</h3></Link>
                </div>
                <div className = "column">
                    <img className="pic" src="imgs/toms-logo.png" alt="Tom's of Maine"></img>
                    <Link to={`${url}/Toms`} style={{ textDecoration: 'none', color: 'black'}} ><h3>Tom's of Maine</h3></Link>
                </div>
                <div className = "column">
                    <img className="pic" src="imgs/elta-md-logo.png" alt="EltaMD"></img>
                    <Link to={`${url}/EltaMD`} style={{ textDecoration: 'none', color: 'black'}} ><h3>EltaMD</h3></Link>
                </div>
            </div>
        </div>
    );
}


export default Company;