import { useState, useEffect, useRef } from 'react';
import './Company.css';
import Select from "react-select";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';


function Hills() {
    // let data = require('./hills.json');
    const [region, setRegion] = useState(null);
    const [country, setCountry] = useState(null);
    const [lang, setLang] = useState(null);
    const [collection, setCollection] = useState(null);
    const [template, setTemplate] = useState(null);
    const [countryList, setCountryList] = useState([]);
    const [langList, setLangList] = useState([]);
    const [apicollection, setApiCollection] = useState([]);
    const [collectionList, setCollectionList] = useState([]);
    const [templateList, setTemplateList] = useState([]);
    const [link, setLink] = useState("");
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const timer = useRef();

    // sets the region selected and retrieves countryList after selecting a region
    const handleRegionChange = (obj) => {
        setRegion(obj);
        setCountryList(obj.children);
        setCountry(null);
        setLang(null);
        setTemplate(null);
        setCollection(null);
        setLink("");
        // console.log(countryList);
    }

    // sets the country selected and retrieves language list after selecting a country
    const handleCountryChange = (obj) => {
        setCountry(obj);
        setLangList(obj.children);
        setLang(null);
        setTemplate(null);
        setCollection(null);
        // console.log(langList);
    };

    // sets the language and retrieves collection list
    const handleLanguageChange = (obj) => {
        setLang(obj);
        setCollectionList(obj.children)
        setCollection(null);
        setTemplate(null);
        // console.log(langSelected);
    };

    //sets the collection and retrieves template list
    const handleCollectionChange = (obj) => {
        setCollection(obj);
        setTemplateList(obj.children)
        setTemplate(null);
    }

    //sets the template
    const handleTemplateChange = (obj) => {
        setTemplate(obj)
    }

    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    useEffect(() => {
        setLoading(false);
        if (!loading) {
            setSuccess(false);
            setLoading(true);
            timer.current = window.setTimeout(() => {
                setSuccess(true);
                setLoading(false);
            }, 2000);
        }
        return () => {
            clearTimeout(timer.current);
        };
    }, [link]);

    useEffect(() => {
        getData();
        async function getData() {
            const response = await fetch(`${process.env.REACT_APP_API_LINK}/companyselection/Hills`);
            const data = await response.json();
            setApiCollection(data);
        }
    }, [])

    useEffect(() => {
        if (region && country && lang && collection && template) {
            getLink();
            async function getLink() {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_LINK}/downloadfiles?fileName=Hills/${region.name}/${country.name}/${lang.name}/${collection.name};${template.name}.zip`);
                    const data = await response.json();
                    //const data = { url: 'www.google.com' };
                    setLink(data);                    
                }
                catch (err) {
                    alert(err.name);
                    alert(err.message);
                }
            }
        }
    }, [region, country, lang, collection, template]);

    //console.log(apicollection)
    //console.log(link.url)

    return (
        <div className="page">
            <div className="logo">
                <h1 className="title">Hill's Pet Nutrition</h1>
                <img src="/imgs/hills-logo.png" alt="Hills"></img>
            </div>
            <div className="filters">
                <b>1. Region</b>
                <Select
                    placeholder="Select Region"
                    value={region}
                    options={apicollection}
                    onChange={handleRegionChange}
                    getOptionLabel={x => x.name}
                    getOptionValue={x => x.name}
                />
                <br />
                <b>2. Country</b>
                <Select
                    placeholder="Select Country"
                    value={country}
                    options={countryList}
                    onChange={handleCountryChange}
                    getOptionLabel={x => x.name}
                    getOptionValue={x => x.name}
                />
                <br />
                <b>3. Language</b>
                <Select
                    placeholder="Select Language"
                    value={lang}
                    options={langList}
                    onChange={handleLanguageChange}
                    getOptionLabel={x => x.name}
                    getOptionValue={x => x.name}
                />
                <br />
                <b>4. Collection</b>
                <Select
                    placeholder="Select Collection"
                    value={collection}
                    options={collectionList}
                    onChange={handleCollectionChange}
                    getOptionLabel={x => x.name}
                    getOptionValue={x => x.name}
                />
                <br />
                <b>5. Template</b>
                <Select
                    placeholder="Select Template"
                    value={template}
                    options={templateList}
                    onChange={handleTemplateChange}
                    getOptionLabel={x => x.name}
                    getOptionValue={x => x.name}
                />
                <div>
                    <Box sx={{ m: 1, position: 'relative' }}>
                        <Button
                            variant="contained"
                            sx={buttonSx}
                            disabled={loading}
                            //onClick={handleButtonClick}
                            href={link.url}
                        >
                            Download
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                    {/* <button className="download"><a className="button_link" href={link.url}>Download</a></button> */}
                    <h4>If you have any questions please contact your Hills account representative.</h4>
                </div>
            </div>
        </div>
    )
}

export default Hills;