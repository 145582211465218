import './landing.css';
import Company from './companySelect';
import EltaMD from './EltaMD';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { path } from 'express/lib/application';

function App() {
 let urlpath =  window.location.href;
 console.log("urlpath :" +urlpath);
 let pathArray = urlpath.split("/");
 let domain = pathArray[2];
 console.log(domain);
  return (
    <div className="landing">
      <Router>
        <Switch>
          <Route domain="test.eltamdproducts.com">
            <EltaMD />
          </Route>
          <Route path = "/companyselect">
            <Company />
          </Route>
          <Route path = "/abc">
            <EltaMD />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

function Home(){
  return(
    <div className = "intro">
        <h1>Colgate Palmolive</h1>
        <h1>Customer Self Service Portal</h1>
        <Link to="/companyselect" style={{ textDecoration: 'none', color: 'white'}}><h2>Start Here</h2></Link>
    </div>
  );
}

export default App;
