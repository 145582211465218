import { useState, useEffect } from 'react';
import Select from "react-select";
import './Company.css';

function EltaMD(){
    const [region, setRegion] = useState(null);
    const [country, setCountry] = useState(null);
    const [lang, setLang] = useState(null);
    const [collection, setCollection] = useState(null);
    const [template, setTemplate] = useState(null);
    const [countryList, setCountryList] = useState([]);
    const [langList, setLangList] = useState([]);
    const [apicollection, setApiCollection] = useState([]);
    const [collectionList, setCollectionList] = useState([]);
    const [templateList, setTemplateList] = useState([]);
    const [link, setLink] = useState("");
    
    // sets the region selected and retrieves countryList after selecting a region
    const handleRegionChange = (obj) => {
        setRegion(obj);
        setCountryList(obj.children);
        setCountry(null);
        setLang(null);
        setTemplate(null);
        setCollection(null);
        setLink("");
        // console.log(countryList);
    }

    // sets the country selected and retrieves language list after selecting a country
    const handleCountryChange = (obj) => {
        setCountry(obj);
        setLangList(obj.children);
        setLang(null);
        setTemplate(null);
        setCollection(null);
        // console.log(langList);
    };

    // sets the language and retrieves collection list
    const handleLanguageChange = (obj) => {
        setLang(obj);
        setCollectionList(obj.children)
        setCollection(null);
        setTemplate(null);
        // console.log(langSelected);
    };

    //sets the collection and retrieves template list
    const handleCollectionChange = (obj) => {
        setCollection(obj);
        setTemplateList(obj.children)
        setTemplate(null);
    }

    //sets the template
    const handleTemplateChange = (obj) => {
        setTemplate(obj)
    }

    useEffect(() => {
        getData();
        async function getData() {
            const response = await fetch(`${process.env.REACT_APP_API_LINK}/companyselection/EltaMD`);
            const data = await response.json();
            setApiCollection(data);
        }
    },[])


    useEffect(() => {
        if (region && country && lang && collection && template) {
            getLink();
            async function getLink() {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_LINK}/downloadfiles?fileName=EltaMD/${region.name}/${country.name}/${lang.name}/${collection.name};${template.name}.zip`);
                    const data = await response.json();
                    setLink(data);
                }
                catch (err){
                    alert(err.name);
                    alert(err.message);
                }
            }
        }
      }, [region, country, lang, collection, template]);
      
    // console.log(apicollection)
    // console.log(link.url)

    return(
        <div className = "page">
            <div className = "logo">
                <h1 className = "title">EltaMD</h1>
                <img src="/imgs/elta-md-logo.png" alt="EltaMD"></img>
            </div>
            <div className = "filters">
                <b>1. Region</b>
                <Select
                    placeholder="Select Region"
                    value={region}
                    options={apicollection}
                    onChange={handleRegionChange}
                    getOptionLabel={x => x.name}
                    getOptionValue={x => x.name}
                />
                <br />
                <b>2. Country</b>
                <Select
                    placeholder="Select Country"
                    value={country}
                    options={countryList}
                    onChange={handleCountryChange}
                    getOptionLabel={x => x.name}
                    getOptionValue={x => x.name}
                />
                <br />
                <b>3. Language</b>
                <Select
                    placeholder="Select Language"
                    value={lang}
                    options={langList}
                    onChange={handleLanguageChange}
                    getOptionLabel={x => x.name}
                    getOptionValue={x => x.name}
                />
                <br />
                <b>4. Collection</b>
                <Select
                    placeholder="Select Collection"
                    value={collection}
                    options={collectionList}
                    onChange={handleCollectionChange}
                    getOptionLabel = {x => x.name}
                    getOptionValue = {x => x.name}
                />
                <br />
                <b>5. Template</b>
                <Select
                    placeholder="Select Template"
                    value={template}
                    options={templateList}
                    onChange={handleTemplateChange}
                    getOptionLabel = {x => x.name}
                    getOptionValue = {x => x.name}
                />
                <div>
                    <button className = "download"><a className = "button_link" href = {link.url}>Download</a></button>
                    <h4>If you have any questions please contact your EltaMD account representative.</h4>
                </div>
            </div>
        </div>
    )
}


export default EltaMD;